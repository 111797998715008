@include media-breakpoint-down(sm) {
  .rbc{

    &-header{
      white-space: pre-wrap!important;
      padding: 1px 5px!important;
    }
    &-date{
      &-cell{
        a{
          font-size: 0.6em;
        }
      }
    }

    &-event{
      &-custom{

        &-tasks-container{
          line-height: 1rem!important;
          border-radius: 0px;
          min-height: 1rem;
        }
      }
    }

  }

  .fc-button-group{
    text-align: center!important;
    text-decoration: none!important;
    .btn-link{
      &:focus, & +.focus{
        text-decoration: none!important;
      }
    }
  }
  .navbar {
    .user-info {
      span {
    //    visibility: hidden;
      }
    }
  }
  .table-container{
    overflow-x: hidden;
    overflow-y: -moz-hidden-unscrollable;
  }
  .more-info{
   cursor: pointer;
  }
  .work-items-container table td , .work-items-container table th{
    padding: 2px 12px!important;
    font-size: 0.8rem!important;
    line-height: 1.1!important;

  }

  [role="main"] {
    min-width: 100%;
    max-width: 100%;
  }

  .container-fluid {
    .container-fluid {
      padding: 0;
    }
  }

  .sidebar {
    &.is-collapsed {
      min-width: 0;
      max-width: 0;
      + [role="main"] {
        min-width: 100%;
        max-width: 100%;
      }
    }
  }

  .dashboard {
    table {
      td:nth-child(3), td:nth-child(4) {
        display: none;
      }
    }
  }

  .user-list {
    table {
      th:nth-child(2), td:nth-child(2) {
        display: none;
      }
    }
  }
}


@include media-breakpoint-down(md) {
  .work-items-container table td , .work-items-container table th{
    padding: 2px 12px!important;
    font-size: 0.8rem!important;
    line-height: 1.1!important;
  }

}