/**
icon-font Webfont
*/
//@base-url: fontURL; //icon-font

//@base-url path is provided from variable.less file.

@font-face {
  font-family: "icon-font";
  src: url("#{$font-icon-url}icon-font.eot?1535036288759");
  src: url("#{$font-icon-url}icon-font.eot?#iefix-1535036288759")
      format("embedded-opentype"),
    url("#{$font-icon-url}icon-font.woff2?1535036288759") format("woff2"),
    url("#{$font-icon-url}icon-font.woff?1535036288759") format("woff"),
    url("#{$font-icon-url}icon-font.ttf?1535036288759") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@import "../../../scss/includes/_variables.scss";
.icon {
  font-family: "icon-font";
  display: inline-block;
  vertical-align: middle;
  line-height: 1.2;
  font-weight: normal;
  font-style: normal;
  speak: none;
  text-decoration: inherit;
  font-size: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #20bcad;
  /*
	&:hover{
		color:#0a4453;
	}*/
}

/*
Glyphs list
*/

.icon-nurse {
  display: inline-block;
  background: url("../../../images/icons/nurse-black.svg") no-repeat;
  width: 16px;
  margin-left: 3px;
  height: 24px;
  filter: invert(20%) sepia(12%) saturate(4986%) hue-rotate(154deg)
    brightness(90%) contrast(92%);
  /*
	&:hover{
		filter: invert(46%) sepia(99%) saturate(1259%) hue-rotate(165deg) brightness(98%) contrast(92%)!important;
	}
*/
}

.icon-x-ray {
  display: inline-block;
  background: url("../../../images/icons/x-ray.svg") no-repeat;
  width: 16px;
  margin-left: 3px;
  height: 24px;
  filter: invert(20%) sepia(12%) saturate(4986%) hue-rotate(154deg)
    brightness(90%) contrast(92%);
  /*
	&:hover{
		filter: invert(46%) sepia(99%) saturate(1259%) hue-rotate(165deg) brightness(98%) contrast(92%)!important;
	}
*/
}

.icon-arrow:before {
  content: "\ea01";
}

.icon-calendar:before {
  content: "\ea02";
}

.icon-cancel:before {
  content: "\ea03";
}

.icon-check {
  &:before {
    content: "\ea04";
    color: $dark-blue;
  }
}
.icon-chevron-down:before {
  content: "\ea05";
}

.icon-commercial-machine:before {
  content: "\ea06";
}

.icon-dashboard {
  &:before {
    content: "\ea07";
    color: $dark-blue;
  }
  /*	&:hover:before{
			color:$aqua-blue!important;;
		}*/
}

.icon-delete:before {
  content: "\ea08";
  color: $dark-blue;
}

.icon-edit:before {
  content: "\ea09";
}

.icon-faq:before {
  content: "\ea0a";
}

.icon-hamburger:before {
  content: "\ea0b";
}

.icon-info {
  $content: "\ea0c";

  &:before {
    content: $content;
    color: $dark-blue !important;
  }
  & .dark {
    &:before {
      content: $content;
      color: $dark-blue !important;
    } /*
		&:hover:before {
			content: $content;
			color:$aqua-blue!important;
		}*/
  }
}

.icon-list:before {
  content: "\ea0d";
}

.icon-radio-checked:before {
  content: "\ea0e";
}

.icon-radio-unchecked:before {
  content: "\ea0f";
}

.icon-reset:before {
  content: "\ea10";
}

.icon-settings:before {
  content: "\ea11";
}

.icon-shopping:before {
  content: "\ea12";
}

.icon-users {
  &:before {
    content: "\ea13";
    color: $dark-blue;
  } /*
&:hover:before{
	color:$aqua-blue!important;;
}*/
}
