@import './_variables.scss';

body {
  font-weight: 300;
  overflow-x: hidden;
}
.pretty .state label:before,
.pretty .state label:after {
  content: '';
  width: calc(1.5em + 2px) !important;
  height: calc(1.5em + 2px) !important;
}
#calendar {
  @include media-breakpoint-down(sm) {
    transition: 0s !important;
  }

  @include media-breakpoint-up(md) {
    transition: 1.5s !important;
  }
}
.btn-font {
  &-size {
    &-1 {
      font-size: 1em !important;
    }
  }
}
.label {
  &-date {
    line-height: 2;
    &-list {
      padding-top: 1rem !important;
    }
    &-calendar {
      padding-top: 0rem !important;
    }
  }
}
.shadow {
  &-true {
    &:focus {
      box-shadow: 8px 8px 5px -1px rgba(0, 0, 0, 0.26) !important;
    }
  }
  &-false {
    box-shadow: none !important;
  }
}
.equipment {
  &-list {
    &-item {
      &-form {
        height: 4rem !important;
      }
    }
  }
}
.bg {
  &-green {
    &-false {
      color: $whiteBrt !important; /////////
      background-color: $gray-400 !important;
      border: 2px solid $gray-400 !important;
    }

    &-true {
      color: $whiteBrt !important;
      background-color: $primary !important;
      border: 2px solid $primary !important;
      & + .shadow-btn {
        -webkit-box-shadow: 39px 41px 5px -1px rgba(0, 0, 0, 0.96);
        -moz-box-shadow: 39px 41px 5px -1px rgba(0, 0, 0, 0.96);
        box-shadow: 39px 41px 5px -1px rgba(0, 0, 0, 0.96) !important;
      }
    }
  }
  &-red {
    &-false {
      color: $dark-blue !important;
      background-color: $gray-400 !important;
      border: 2px solid $gray-400 !important;
    }

    &-true {
      background-color: $red !important;
      border: 2px solid $red !important;
      & + .shadow-btn {
        -webkit-box-shadow: 39px 41px 5px -1px rgba(0, 0, 0, 0.96);
        -moz-box-shadow: 39px 41px 5px -1px rgba(0, 0, 0, 0.96);
        box-shadow: 39px 41px 5px -1px rgba(0, 0, 0, 0.96) !important;
      }
    }
  }
} /*
.equipment{
  &-list{
    &-item{
     max-height: 0vh;
    }
  }
}*/
.switcher {
  &-container {
    display: block;
    position: relative;
    padding: 2px 5px;
    &-wrapper {
      display: inline-block;
      border-radius: 100px;
      min-width: 2.95rem;
      min-height: 1.5rem;
      transition: 0.9s;
      border: 1px solid $not-active-gray;
      & + .active {
        border: 1px solid $aqua-blue;
      }
      &-circle {
        margin-left: 0rem; //-1.4rem
        display: inline-block;
        position: absolute;
        border-radius: 100px;
        min-height: 1.4rem;
        min-width: 1.4rem;
        transition: 0.9s;
        //  background-color: $not-active-gray;
        /* & + .active{
       margin-left: -1.4rem!important;
       background-color: $aqua-blue!important;
     }*/
      }
    }
  }
}
#tbodyContainer_ListView,
#taskListCalendar_Children {
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0.36rem !important;
    &-button {
      background-color: $brt-blue;
      height: 1rem;
    }
    &-thumb {
      max-height: 2rem !important;
      background-color: #a1d9d5; //235 190 85
      width: 0.3rem !important;

      &:hover {
        background-color: rgba(32, 188, 173, 1);
      }
    }
    &-track {
      background-color: $body-bg;
      :hover {
        background-color: $brand-primary;
      }
    }
  }
}
.text {
  &-fonts-normal {
    font-family: inherit;
    font-weight: 500;
    font-size: 1rem;
  }
  &-dark-blue {
    color: $dark-blue !important;
  }
}
.table-container {
  &::-webkit-scrollbar {
    width: 0.36rem !important;
    &-button {
      background-color: $brt-blue;
      height: 1rem;
    }
    &-thumb {
      max-height: 2rem !important;
      background-color: #a1d9d5; //235 190 85
      width: 0.3rem !important;

      &:hover {
        background-color: rgba(32, 188, 173, 1);
      }
    }
    &-track {
      background-color: $body-bg;
      :hover {
        background-color: $brand-primary;
      }
    }
  }
}
h1,
h2 {
  font-weight: 700;
}

h3 {
  font-weight: 400;
}

label {
  font-weight: 500;
  font-size: 13px;
  margin-bottom: 2px;
  &-date {
    vertical-align: middle;
    line-height: 2;
    padding-top: 12px;
  }
}

.h-100-per,
body,
html {
  height: 100%;
  &::-webkit-scrollbar {
    width: 0.66rem !important;
  }

  &::-webkit-scrollbar-button {
    background-color: #ddf8f8;
    height: 1rem;
  }

  &::-webkit-scrollbar-track {
    background-color: #f5f7fa;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a1d9d5;
    width: 0.6rem !important;
  }
}
.h-100-vh {
  height: 100vh;
}

.bg-dark {
  color: $white;
}
.border {
  $border-width: 1px;
  &-all {
    border: $border-width solid $border-color;
    &-none {
      border: 0px solid $border-color;
    }
  }
  &-top {
    border-top: $border-width solid $border-color;
  }
  &-bottom {
    border-bottom: $border-width solid $border-color;
  }
  &-left {
    border-left: $border-width solid $border-color;
  }
  &-right {
    border-right: $border-width solid $border-color;
  }
}
.bg-white {
  background-color: $whiteBrt;
  + .border {
    $border-width: 1px;
    &-all {
      border: $border-width solid $border-color;
    }
    &-top {
      border-top: $border-width solid $border-color;
    }
    &-bottom {
      border-bottom: $border-width solid $border-color;
    }
    &-left {
      border-left: $border-width solid $border-color;
    }
    &-right {
      border-right: $border-width solid $border-color;
    }
  }
}
.rotate {
  transition: 0.8s !important;
  &-container {
    position: relative;
    display: inline-block;
  }
  &-0deg {
    &:after {
    }
    transform: rotate(0deg) !important;
  }
  &-180deg {
    left: 0;
    transform: rotate(180deg) !important;
  }
}
.dropdown {
  &-custom {
    font-family: inherit;
    font-weight: 500;
    font-size: 1rem;
  }
  &-menu {
    font-size: $font-size-sm;
    @include border-radius($dropdown-border-radius);
  }
}

#taskListCalendar_Children {
  > div {
    > div {
      @include media-breakpoint-down(sm) {
        overflow-y: scroll !important;
        width: 96%;
      }
    }
  }
}

#floating-panel {
  position: absolute;
  top: 10px;
  left: 25%;
  z-index: 5;
  background-color: #fff;
  padding: 5px 5px 5px 10px;
  border: 1px solid #999;
  text-align: center;
  font-family: 'Roboto', 'sans-serif';
  line-height: 30px;
}

.card {
  background-color: $whiteBrt;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin-bottom: 15px !important;
  color: $dark-blue;
  &.m0 {
    margin-bottom: 0px !important;
  }
  h5 + &-title {
    font-weight: 300 !important;
  }
  &-body {
    padding: 0.9375rem;

    &-custom {
      @include media-breakpoint-down(sm) {
        text-align: center !important;
        padding: 0rem;
      }
    }
    p {
      margin: 0;
    }
  }
}
.overflow-scroll {
  overflow: scroll !important;
}
.no-box-shadow {
  box-shadow: none !important;
}
.modal-header-custom {
  border-bottom: 0px solid $brand-primary;
  .close {
    color: $brand-primary;
    :hover {
      color: $yellow;
    }
  }
}
.counter-approved {
  &-container {
    /*@include media-breakpoint-only(md) {
      padding-left:3rem;
      padding-top: 0.5rem;
    }
    @media all and (min-width:1350px) and (max-width:1550px){
      padding-left:1rem;
      padding-right:1rem;
      padding-top: 0.5rem;
    }
    @media all and (min-width:1550px) and (max-width:1750px){
      padding-left:3rem;
      padding-top: 0.5rem;
    }*/
  }
}
.rotate {
  &-container {
    z-index: 0;

    @media all and (max-width: 1350px) {
      margin-top: 28%;
      margin-bottom: 28%;
      margin-left: 1rem;
      margin-right: 1rem;
    }
    @media all and (min-width: 1350px) and (max-width: 1450px) {
      margin-top: 28%;
      margin-bottom: 28%;
      margin-left: 1.2rem;
      margin-right: 1.2rem;
    }
    @media all and (min-width: 1450px) and (max-width: 1550px) {
      margin-top: 24%;
      margin-bottom: 24%;
      margin-left: 1.45rem;
      margin-right: 1.45rem;
    }
    @media all and (min-width: 1550px) and (max-width: 1650px) {
      margin-top: 22%;
      margin-bottom: 22%;
      margin-left: 1.65rem;
      margin-right: 1.65rem;
    }
    @media all and (min-width: 1650px) and (max-width: 1750px) {
      margin-top: 22%;
      margin-bottom: 22%;
      margin-left: 1.85rem;
      margin-right: 1.85rem;
    }
    @media all and (min-width: 1750px) and (max-width: 1850px) {
      margin-top: 22%;
      margin-bottom: 22%;
      margin-left: 2.05rem;
      margin-right: 2.05rem;
    }
    @media all and (min-width: 1850px) and (max-width: 1950px) {
      margin-top: 22%;
      margin-bottom: 22%;
      margin-left: 2.25rem;
      margin-right: 2.25rem;
    }
  }
}

/*
.map{
  &-wrapper{
    & > div > div > div:not(.loader-map) {
      height:40vh!important;
      transform: 0.8s;
    }
  }
}
*/
.modal-footer-custom {
  border-top: 0px solid $brand-primary;
}
.btn {
  &-map {
    &-tooltips {
      &-close {
        &:hover {
          color: $brand-primary !important;
          cursor: pointer;
        }
      }
    }
  }
  &-approved {
    color: $white;
    background-color: $brand-primary;
    padding: 10px 16px;
  }
  &-primary {
    color: $white;
    &-lite {
      filter: invert(0%) sepia(100%) saturate(1%) hue-rotate(161deg) brightness(105%) contrast(101%) !important;
    }
  }
}

.btn-outline-primary {
  &:hover {
    color: $white;
  }
}

.btn,
a {
  &:not(.shadow-true):focus {
    box-shadow: none !important;
  }

  &:hover {
    text-decoration: none;
  }

  &.light {
    color: #ccc;
    font-size: 14px;
    font-weight: 200;
  }

  &.change-views {
    font-size: 12px;
    font-weight: 400;
  }

  .icon-calendar,
  .icon-list {
    margin-top: -3px;
    margin-right: 8px;
  }
}
.icon {
  &.left-nav {
    color: $gray-600;
  }
}
.icon-for-switcher {
  margin-top: 0.6rem !important;
  margin-bottom: 0.6rem !important;
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}
.arrow {
  & {
    &:before {
      border-bottom-color: $whiteBrt !important;
    }
  }
}
.filter {
  &-btn {
    cursor: pointer !important;
  }
}
.approved {
  &-dropdown {
    &-item {
      font-family: inherit;
      font-weight: 500;
      font-size: 1rem;
      color: $dark-blue !important;
      &.disabled {
        background-color: $soft-green !important;
        color: $whiteBrt !important;
      }
      &:hover {
        opacity: 1 !important;
        background-color: $soft-green !important;
        color: $whiteBrt !important;
        transition: 0.6s !important;
      }
    }
  }
}
.fonts-inherit {
  font-family: inherit;
  font-weight: 500;
  font-size: 1rem;
}

.reject {
  &-dropdown {
    &-item {
      font-family: inherit;
      font-weight: 500;
      font-size: 1rem;
      color: $dark-blue !important;
      &.disabled {
        color: $whiteBrt !important;
        background-color: $red !important;
      }
      &:hover {
        opacity: 0.7 !important;
        background-color: $red !important;
        color: $whiteBrt !important;
        transition: 0.6s !important;
      }
    }
  }
}

.bg {
  &-transparent {
    background-color: transparent !important;
  }
  &-brand {
    color: $whiteBrt;
    background-color: $primary !important;
  }
  &-red {
    color: $whiteBrt;
    background-color: $red !important;
  }
}
.fc-button-group {
  .btn {
    padding: 0.375rem 0 0;
    margin: 0 2.75rem;

    &.active {
      font-weight: 500;
      border-bottom: 2px solid $brand-primary;
    }
  }
}
.befor-btn {
  &:before {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
  }
}
button {
  &.fc-next-button,
  &.fc-prev-button {
    &.fc-button {
      &.fc-next-button {
        &:before {
          // right: -14px;
        }
      }
      &.fc-prev-button {
        &:before {
          //  left: -14px;
        }
      }
    }
    &.icon {
      font-family: $font-family-base;

      &:before {
        font-family: 'icon-font';
        position: absolute;
        font-size: 10px;
        color: $gray-600;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
      }
      & + .left-nav:before {
        font-family: 'icon-font';
        position: absolute;
        font-size: 10px;
        color: $gray-600 !important;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
      }
    }
  }

  &.fc-next-button {
    &:before {
      transform: rotate(270deg);
      //     right: 7px;
    }
  }
  &.fc-prev-button {
    &:before {
      transform: rotate(90deg);
      //    left: 7px;
    }
  }
}
.left {
  &-4 {
    left: -2px !important;
  }
}
.aiym {
  $zIndex: 0;
  // background: rgba(128, 122, 108, 0.7);
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important;
  z-index: $zIndex;
  &-item {
    z-index: 15;
    opacity: 1;
  }
  &-bg {
    background: rgba(128, 122, 108, 0.9);
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0rem;
    left: -1.5rem;
    z-index: -5;
  }
  &-list-tooltip {
    background-color: transparent !important;
    margin-left: 0rem !important;
    margin-right: 0rem !important;
    &-item {
      background-color: transparent !important;
      border: 0px solid black;
      font-size: 1em;
      padding-top: 0.4rem !important;
      padding-bottom: 0.2rem !important;
      min-width: 11rem;
    }
  }
}

.recharts {
  &-label {
    &-custom {
      position: absolute;
      transform: rotate(-90deg) !important;

      @media screen and (max-width: 1908px) {
        top: 14% !important;
        left: -6rem;
        width: 290px;
        font-family: 'Roboto', sans-serif;
        font-size: 1rem;
      }
      @media screen and (min-width: 1908px) {
        top: 24% !important;
        left: -5.6rem;
        width: 290px;
        font-family: 'Roboto', sans-serif;
        font-size: 1rem;
      }

      @include media-breakpoint-only(xs) {
        display: none !important;
      }
    }
    @include media-breakpoint-up(xs) {
      display: none !important;
    }
    @include media-breakpoint-down(lg) {
      top: 14% !important;
    }
  }
  &-wrapper {
    transform: rotate(90deg);

    @include media-breakpoint-up(sm) {
      transform: rotate(0deg);
    }
    @include media-breakpoint-down(xs) {
      transform: rotate(90deg);
    }
  }
  &-legend-wrapper {
    bottom: -20px !important;
  }
}
/*-----btn 0.1.0  */
/*:root {
  --accent: #04da97;
  --border-width: 6px;
  --border-radius: 55px;
  --font-size: 30px;
}*/
/*
body {
}

.container {
  perspective: 800px;
}
*/
.toggle-row {
  height: 4vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background-color: transparent;
  /*
    @include media-breakpoint-down(xs) {
    }
    @include media-breakpoint-only(sm){
      margin-top:1rem;
      margin-bottom:0.5rem;
    }


  */
  @include media-breakpoint-down(lg) {
    padding-left: 0rem !important;
  }
  @media (min-width: 1200px) and (min-width: 1620px) {
    padding-left: 2rem !important;
  }
  @media (min-width: 1620px) and (min-width: 1920px) {
    padding-left: 22rem !important;
  }
  @media (min-width: 1920px) and (min-width: 2500px) {
    padding-left: 42rem !important;
  }
}
.toggle-container-view-btn {
  perspective: 800px;
}
#button-list {
  color: $brand-secondary !important;
  &.active {
    color: $brand-primary !important;
  }
}
#button-calendar {
  color: $brand-secondary !important;
  &.active {
    color: $brand-primary !important;
  }
}

#button-next {
  color: $not-active-gray;
  &:hover {
    color: $brand-primary !important;
  }
}
#button-prev {
  color: $not-active-gray;
  &:hover {
    color: $brand-primary !important;
  }
}
.fc-button-group > .white-text {
  color: $white;
  & .active {
    color: $brand-primary !important;
  }
}

.toggle-view-btn {
  position: relative !important;
  border: solid var(--border-width) var(--accent);
  border-radius: var(--border-radius);
  transition: transform cubic-bezier(0, 0, 0.3, 2) 0.4s;
  transform-style: preserve-3d;
  perspective: 800px;
  @include media-breakpoint-only(xs) {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
  @include media-breakpoint-up(sm) {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }
}

.toggle-view-btn > input[type='radio'] {
  display: none;
}

.toggle > #choice1:checked ~ #flap {
  transform: rotateY(-180deg);
}

.toggle-view-btn > #choice1:checked ~ #flap > .cnt {
  transform: rotateY(-180deg);
}

.toggle-view-btn > #choice2:checked ~ #flap > .cnt {
  transform: rotateY(0deg);
}

.toggle-view-btn > label {
  display: inline-block;
  min-width: 170px;
  padding: 7px !important;
  font-size: var(--font-size);
  text-align: center;
  color: $dark-blue;
  cursor: pointer;
  border: 2px solid $dark-blue;
  &.active {
    color: $whiteBrt;
    border: 2px solid $brand-primary;
    background-color: $brand-primary;
  }
}

.toggle-view-btn > label,
.toggle-view-btn > #flap {
  font-weight: 300;
  text-transform: capitalize;
}

.toggle-view-btn > #flap {
  position: absolute;
  top: 0%;
  left: 50%;
  height: calc(100% + var(--border-width) * 2);
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--font-size);
  background-color: var(--accent);
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  transform-style: preserve-3d;
  transform-origin: left;
  transition: transform cubic-bezier(0.4, 0, 0.2, 1) 0.5s;
  z-index: 1000000000000;
}
button {
  &:focus {
    outline: none !important;
  }
}
.butter {
  &-list {
    &-item {
      padding-left: 0.2rem;
      padding-right: 0.6rem !important;
    }
  }
}

.toggle-view-btn > #flap > .cnt {
  color: $whiteBrt;
  border: 2px solid $brand-primary;
  background-color: $brand-primary;
  transition: transform 0s linear 0.2s;
  transform-style: preserve-3d;
  padding: 7px !important;
  height: 2.5rem;
  min-width: 170px;
  font-weight: bolder;
  @include media-breakpoint-only(xs) {
    height: 2.6rem;
  }

  @include media-breakpoint-only(sm) {
    height: 2.6rem;
  }

  @include media-breakpoint-only(md) {
    height: 2.6rem;
  }
  @include media-breakpoint-only(lg) {
    height: 2.6rem;
  }

  @include media-breakpoint-only(xl) {
    height: 2.6rem;
  }
}
.tooltip-inner {
  /*
  max-width: 200px;
  padding: 0.25rem 0.5rem;*/
  color: #0a4453 !important;
  // text-align: center;
  background-color: #f5f7fa !important; /*
  border-radius: 0;*/
}

// Responsive css
@media (max-width: 576px) {
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
@media (max-width: 992px) {
  .navbar-expand-lg {
    .navbar-nav {
      .nav-link {
        padding-right: 0;
      }
    }
  }
}

.tooltip {
  pointer-events: none;
}
