@import "./_variables.scss";
.ReactModal__Overlay {
    z-index: 100;
}

.modal-content {
    background-color: $whiteBrt;
    color: $dark-blue;

    .modal-body {
        padding: 2rem;

        p {
            font-size: 13px;
            font-weight: 300;
        }
    }

    .close-btn {
        &:hover {
            text-decoration: none;
        }
    }
}