.settings {
  .profile-image {
    height: 100px;
    position: relative;
    text-align: center;
    width: 100px;

    img {
      border-radius: 50%;
      max-width: 100%;
    }
  }

  .sub-menu {
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.3);
    .list-group-item {
      cursor: pointer;
      &.active {
        background-color: $white;
        border-color: rgba(0, 0, 0, 0.125);
        border-right: 2px solid $brand-primary;
        color: $brand-primary;
        font-weight: 500;
      }
    }
  }
}

.list-group-flush {
  .list-group-item {
    background: transparent;
  }
}