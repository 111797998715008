.ReactVirtualized__Grid__innerScrollContainer {
  overflow: visible !important;
}

.ReactVirtualized__Table__Grid {
  outline: 0;

  &::-webkit-scrollbar {
    width: 0.36rem !important;
  }

  &::-webkit-scrollbar-button {
    background-color: #ddf8f8;
    height: 1rem;
  }

  &::-webkit-scrollbar-track {
    background-color: #f5f7fa;
  }

  &::-webkit-scrollbar-thumb {
    max-height: 2rem !important;
    background-color: #a1d9d5;
    width: 0.3rem !important;
  }
}
