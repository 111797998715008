@import "./_variables.scss";
.list-view {
    .pagination {
        margin: 0;
    }
}
.table{
    &-container{
        &-item{

            &  td {
                //      background-color: $brand-primary;

            }
            &  > th{
                $width:70;
                @include media-breakpoint-down(md) {
                    font-weight: 400;
                    width:($width)px ;
                }
                @include media-breakpoint-only(lg) {
                    font-weight: 400;
                    width: ($width + 2px)!important;
                }

                @include media-breakpoint-up(xl) {
                    font-weight: 400;
                    width: ($width*2px)!important;
                }

            }

            &  > th {
                $width:20;

            }
            &  > td{
                $border:1px solid #737779;
                &:nth-child(3){
                    $width:4;

                    @include media-breakpoint-down(md) {
                        width:($width*2.5)vw!important;
                    }

                    @include media-breakpoint-only(lg) {
                        text-align: center;
                        width: ($width*3vw);
                        font-size: 0.8em;
                        padding: 2px 0px;


                    }
                    @media screen and (min-width:1200px) and (max-width:1400px) {
                        width: ($width*5vw);
                        padding: 2px 2px;

                    }
                    @media screen and (min-width:1400px)  {
                        width: ($width*3.8vw);
                        padding: 2px 2px;

                    }

                }
                &:nth-child(4){
                    $width:5;
                    @include media-breakpoint-down(md) {
                        width:($width+5)vw!important;
                    }
                    @include media-breakpoint-only(lg) {
                        text-align: center;
                        width: ($width*1.6vw);
                        font-size: 0.8em;
                        padding: 2px 0px;

                    }
                    @include media-breakpoint-up(xl) {
                        text-align: center!important;
                        width: ($width*2vw)!important;
                    }

                }
                &:nth-child(5){
                    $width:5;
                    @include media-breakpoint-down(md) {
                        width:($width vw) ;
                    }


                    @include media-breakpoint-only(lg) {
                        text-align: center;
                        font-size: 0.8em;
                        padding: 2px 5px;
                        width: ($width*0.5vw);
                    }

                    @include media-breakpoint-up(xl) {
                        text-align: center!important;
                        width: ($width*2vw);
                        //     border-left:$border;
                    }

                }
                &:nth-child(6){
                    $width:3;
                    @include media-breakpoint-down(md) {
                        width:($width)vw ;
                    }

                    @include media-breakpoint-up(lg) {
                        text-align: center!important;
                        width: ($width - 2vw)!important;
                    }
                    @include media-breakpoint-up(xl) {
                        text-align: center!important;
                        width: ($width*2vw)!important;
                    }

                }

            }
            .suggested-change{
                $width:20;
                @include media-breakpoint-down(md) {
                    max-width:($width - 3vw);
                }
                @include media-breakpoint-only(lg) {
                    text-align: left;
                    width: ($width*1vw);
                    font-size: 0.8em;
                    padding: 2px 10px;

                }

                @include media-breakpoint-up(xl) {
                    text-align: center;
                    width: ($width*2vw);
                }

            }
        }
    }

}