.Marker {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &Icon {
    flex: 1;
    height: 1rem;
    padding: 0 4px;
    text-align: center;
    line-height: 1rem;
    border-radius: 2px;
    border: 1px solid rgba(0,0,0,0.3);
    font-weight: bold;
    box-shadow: 0 1px 1px rgba(0,0,0,0.2);

    &_WARN {
      background-color: #fff316;
    }

    &_RISK {
      background-color: orange;
    }

    &_CRITICAL {
      background-color: crimson;
      color: white;
    }

    &_HEALTHY {
      background-color: #88f35a;
    }

    &_cluster {
      position: relative;
      flex: 1 0 30px;
      height: 30px;
      padding: 0 4px;
      background-color: white;
      color: #0c4c5c;
      line-height: 26px;
      border-radius: 50%;
      border: 2px solid #0c4c5c;
    }
  }

  &Tooltip {
    position: absolute;
    left: 50%;
    bottom: calc(100% + 5px);
    margin-left: -100px;
    width: 200px;
    padding: 6px 6px;
    background-color: white;
    border: 1px solid rgb(10, 68, 83);
    font-size: 1rem;
    color: #0a4453;

    &__close {
      position: absolute;
      top: 0;
      right: 0;
    }

    h6 {
      padding-right: 20px;
    }

    p {
      margin-bottom: 5px;
      font-size: 0.75rem;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 50%;
      margin-left: -6px;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 5px solid rgb(10, 68, 83);
    }
  }
}
