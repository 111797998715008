[id*="graph_"]{
    margin: auto;
    margin-bottom: 8px;

    svg {
        height: auto;
        max-height: 300px;
    }

    .timer {
        stroke: #f9cb32;
    }
}

.circle {
    stroke: #f9cb32;
}

.border {
    stroke: #ececec;
}
