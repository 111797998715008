@import "./_variables.scss";
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 54px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  min-width: 200px;
  max-width: 200px;
  transition: max-width 0.4s ease , min-width 0.4s ease;
   span{
    margin-left: 0.8rem;
  }
  .nav-item {
    white-space: nowrap;
    max-width: 100%;

    .hamburger-menu {
      color: $brand-primary;
      cursor: pointer;
      height: 25px;
      float: right;
      margin: 20px;
      transition: margin-right 0.4s ease;
    }
  }
  span {
    opacity: 1;
    transition: opacity 0.4s ease;
  }

  &.is-collapsed {
    max-width: 64px;
    min-width: 64px;
    transition: max-width 0.4s ease , min-width 0.4s ease;

    span {
      opacity: 0;
    }

    & + [role="main"] {
      min-width: calc(100% - 64px);
      max-width: calc(100% - 64px);
      transition: max-width 0.4s ease , min-width 0.4s ease;

      > .globus-alert-primary {
        width: calc(100% - 64px)
      }
    }
  }

  .nav-link {
    font-weight: 100;
    cursor: pointer;
    color: $dark-blue;
    i {
      display: inline-block;
      width: 28px;
      text-align: center;
     // color: $dark-blue;
      img {
        margin-top: -4px;
      }
    }

    &.active {
     // color: $brand-primary;
      font-weight: 500;
      cursor: pointer;
      & > .icon{
     //   color: $brand-primary!important;
      }
    }

  }
 .nav-item{
   a.icon{
     &-users{
       color: $dark-blue;
       &:before{

         opacity: 0;
       }
     }
   }
 }
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 54px);
  padding-top: .5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}

// Sidebar css updates
.sidebar {
  .nav-item {
    white-space: nowrap;
    max-width: 100%;

    .hamburger-menu {
      cursor: pointer;
      display: block;
      float: right;
      margin: 20px;
      transition: margin-right 0.4s ease;
    }
  }

  &.is-collapsed {
    .hamburger-menu {
      transition: margin-right 0.4s ease;
    }
  }

  .nav-link {
    font-weight: 100;
    //color: $gray-700;

    i {
      display: inline-block;
      width: 28px;
      text-align: center;

      img {
        margin-top: -4px;
      }
    }

    &.active {
      color: $brand-secondary;
      font-weight: 500;
    }
  }
}