@import "./_variables.scss";
select {
  &.form-control {
    border: 1px solid $brand-secondary;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url("../../images/icons/arrow.svg") 96% / 10px no-repeat #f6f6f6;
  }
}

/* CAUTION: IE hackery ahead */
select::-ms-expand {
  display: none; /* remove default arrow on ie10 and ie11 */
}

/* target Internet Explorer 9 to undo the custom arrow */
@media screen and (min-width: 0\0) {
  select {
    background: none\9;
    padding: 5px\9;
  }
}

// Work items
.work-items-container {
  overflow-x: hidden;
  .table-container {
    @include media-breakpoint-down(sm) {
      min-height: 6rem;
      max-height: 50vh;
    }
    @include media-breakpoint-only(md) {
      min-height: 6rem;
      max-height: 22rem;
    }
    @include media-breakpoint-only(lg) {
      min-height: 6rem;
      max-height: 29rem;
    }
    @include media-breakpoint-only(xl) {
      min-height: 6rem;
      max-height: 25rem;
    }
  }

  #calendar {
    transition: 1.5s;
    table {
      &-container {
        overflow-x: hidden;
        overflow-y: -moz-hidden-unscrollable;
      }
      thead {
        td {
          border-bottom: none;
        }
      }
      td {
        padding-bottom: 0;
      }
    }
  }
  table {
    margin-bottom: 0;

    tr {
      &.selected {
        th {
          background-color: $aqua-blue;
          color: $dark-blue !important;
          & > a.icon {
            &-check {
              &:before {
                color: $whiteBrt !important;
              }
            }
          }
        }
        td {
          background-color: $brt-blue;
          color: #6e695f;

          a {
            color: #6e695f;
            &.icon {
              &-delete {
                &:before {
                  color: $active-gray !important;
                }
              }
            }
          }
        }
      }

      &:last-child {
        th,
        td {
          border-bottom: 1px solid $dashboard-border-color;

          &.fc-event-container {
            border: none;
          }
        }
      }
    }

    thead {
      th {
        border: none !important;
        padding: 10px 0 5px;
      }
    }
    tbody {
      th {
        font-weight: 400;
        width: 70px;
        p {
          font-size: 10px;
          height: 16px;
          line-height: 16px;
          margin: 0 0 -10px;
          padding: 0;
        }
      }
    }

    a {
      color: $white;
      &.more-info {
        font-size: 14px;
        text-decoration: underline;
        color: $dark-blue;
        &:hover {
          color: $brand-primary;
          text-decoration: none;
        }
      }
    }

    .fc-widget-content {
      padding: 0;
      td {
        padding: 0;
      }
    }

    td,
    th {
      vertical-align: middle;
      border-color: $dashboard-border-color;
      padding: 20px 12px;

      span {
        font-weight: 500;
      }

      &:first-child,
      &:last-child {
        text-align: center;
        border-right: 1px solid $dashboard-border-color;

        a {
          &:hover {
            text-decoration: none;
          }
        }
      }

      &:last-child {
        border-right: none;
        border-left: 1px solid $dashboard-border-color;
      }
    }
  }

  .work-item {
    + tr {
      &:hover {
        background-color: rgba(0, 0, 0, 0);
      }

      .work-item-description {
        font-size: 14px;
        padding: 15px;

        .description-header,
        .description-body {
          > div {
            margin: 1.5rem 0;
            + div {
              border-left: 1px solid #d7dbdd;
            }
          }
        }

        .description-header {
          div {
            border: none !important;

            span {
              font-size: $font-size-lg;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}

.current-month {
  display: inline-block;
  color: $dark-blue;
  width: 250px;
}

.work-items-empty {
  color: $brand-primary;
  min-height: 250px;
  font-size: 20px;

  img {
    height: 133px;
    margin-right: 50px;

    .calendar-view & {
      margin-right: 0;
    }
  }
}
