@import "./_variables.scss";
.task-list {
    $height:40;
    border-left: 1px solid $dashboard-border-color;
    font-size: 14px;
        ul {
            margin: 20px -15px 0 -30px;
            padding: 0;
            //   overflow: auto;
                &.week{
                    @include media-breakpoint-only(md) {
                        min-height: ($height -16vh);
                        max-height:  ($height - 15vh);
                    }
                    @include media-breakpoint-up(lg) {
                        min-height: ($height)vh;
                        max-height:  ($height + 30vh);
                    }

                }
            &.month{


                @include media-breakpoint-only(md) {
                    @media screen and (min-height: 800px){
                        min-height: ($height -16vh);
                        max-height:  ($height - 15vh);

                    }


                }
                @include media-breakpoint-up(lg) {
                    @media screen and (max-height: 1025px){
                        min-height: ($height)vh;
                        max-height:  ($height+12vh);

                    }
                    min-height: ($height)vh;
                    max-height:  ($height+vh);


                }

            }
            li {
                list-style: none;
                padding: 10px 30px;

                .btn {
                    text-transform: uppercase;
                    font-size: 12px;
                    padding-left: 0;
                    padding-right: 0;
                    & + .btn {
                        margin-left: 40px;
                    }
                }

                .task-info {
                    span {
                        font-weight: 500;
                    }
                }


                a {
                    color: $brand-primary;
                    &.more-info {
                        color: $white;
                        font-size: 14px;
                        text-decoration: underline;
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }

                &.active {
                    background-color: $brt-blue!important;
                    color: #827c6e;
                    .task-info {
                        text-decoration: line-through;
                    }

                    .more-info {
                        color: #827c6e;
                    }

                    .btn-reject {
                        color: #3b3b3b;

                        i {
                            margin-top: -3px;
                        }
                    }

                    .btn-done {
                        padding: 0 3px;
                        color: $white;

                        i {
                            font-size: 11px;
                        }
                    }
                }
            }
        }

    @include media-breakpoint-down(sm) {
        border-left:none!important;
        transition: 0s;
    }
    @include media-breakpoint-up(md) {
        border-left:none!important;
        transition: 0.5s;
    }

    ul {
        margin: 20px -15px 0 -30px;
        padding: 0;
     //   overflow: auto;

        @include media-breakpoint-only(md) {
            min-height: ($height -16vh);
            max-height:  ($height - 15vh);
        }
        @include media-breakpoint-up(lg) {
            min-height: ($height -15vh);
            max-height:  ($height - 2vh);
        }
        li {
            list-style: none;
            padding: 10px 30px;

            .btn {
                text-transform: uppercase;
                font-size: 12px;
                padding-left: 0;
                padding-right: 0;
                & + .btn {
                    margin-left: 40px;
                }
            }

            .task-info {
                span {
                    font-weight: 500;
                }
            }


            a {
                color: $brand-primary;
                &.more-info {
                    color: $brand-primary;
                    font-size: 14px;
                    text-decoration: underline;
                    &:hover {
                        color:$brand-secondary;
                    text-decoration: none;
                    }
                }
            }

            &.active {
                background-color: $brt-blue!important;
                color: #827c6e;
                .task-info {
                    text-decoration: line-through;
                }

                .more-info {
                    color: #827c6e;
                }

                .btn-reject {
                    color: #3b3b3b;

                    i {
                        margin-top: -3px;
                    }
                }

                .btn-done {
                    padding: 0 3px;
                    color: $white;

                    i {
                        font-size: 11px;
                    }
                }
            }
        }
    }
}
.checkbox{
    $width:1rem;
     &-container{
         display: inline-block;
         position: relative;
         z-index: $z-index;
         width:($width*2);
         height:$width;
         padding: 2px;
         &-wrapper{
             display: block;
             position: absolute;
             width:$width;
             height:$width;
             border:1px solid $brand-primary;
             transition: 0.8s;
            & .active {
                border:1px solid $dark-blue!important;
                background-color: $brand-secondary;
            }

             z-index: ($z-index+2);
          &-icon{
              font-size: 0.8em;
              z-index: ($z-index+6);
          }
         }
     }
}