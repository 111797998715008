@import "./_variables.scss";

header {

  .white-stripe{
    display: block!important;
    height: 2.2rem!important;
    width: 0px;
    border-right: 1px solid $not-active-gray!important;
  }
  .header{
    &-input{
      &-searchbox{
        -webkit-text-fill-color: $dark-blue!important;
        border-bottom: 2px solid $dark-blue!important;
        &:focus{
          border-bottom: 2px solid $brand-primary!important;
        }
      }
      &-btn{
        background-color: $dark-blue!important;
        border: 2px solid $dark-blue!important;
        &:focus{
          background-color: $brand-primary!important;
        }
      }
    }
    &-list{
      &-item{
        padding: 0.05vh 1.25vh;
      }
    }
    &-custom{
      &-collapse{
      /*&::-webkit-scrollbar{
        width:0.36rem!important;
        &-button{
          background-color: $brt-blue;
          height: 1rem;
        }
        &-thumb{
          max-height: 2rem!important;
          background-color: rgba(32, 188, 173, 0.6);//235 190 85
          width:0.3rem!important;

          &:hover{
            background-color: rgba(32, 188, 173, 1) ;
          }
        }
        &-track{
          background-color: $body-bg;
          :hover{
            background-color: $brand-primary;
          }
        }

      }*/

      }
    }
    &-label{
     // width: 52%;
      @include media-breakpoint-down(xs) {
        width: 56%;
      }
      @include media-breakpoint-up(sm) {
        width: 36%;
      }
      @include media-breakpoint-up(md) {
        width: 85%;
      }
      @include media-breakpoint-up(xl) {
        width: 85%;
      }
       }
    &-btn{
      background-color: $whiteBrt !important;
      &-item{
        transition: 0.8s;
        cursor: pointer;
        color: $brand-secondary!important;
        background-color: transparent!important;
        .dropdown-item{

          color:$not-active-gray!important;
          background-color: transparent!important;
          &:hover{
            color:  $active-gray !important;
            border:  0px solid $whiteBrt;
            outline: none!important;
          }

        }

        &:hover{

          border: 0px solid $whiteBrt;
          outline: none;
        }
        &:active, &:hover, &:focus{
          color: $brand-primary!important;
          border: 0px solid $whiteBrt;
          outline: none;
          box-shadow: none!important;
        }
      }
      &-userinfo{
        color:$not-active-gray!important;
        background-color: transparent!important;
        &-btn{
          font-size: 13px;
          text-align: right;
        }
        :hover{
          color:  $active-gray !important;
          border:  0px solid $whiteBrt;
          outline: none!important;
        }
        &:active, &:hover, &:focus{
          color:  $active-gray !important;
          border: 0px solid $whiteBrt;
          outline: none!important;
          box-shadow: none!important;
        }
    }
  }
  }
.no-outline{
  outline: none!important;
}
  .mobile{
   &-w-h {
     width:4rem;
   }
    &-list-group-item {
      color:$brand-primary;
      background-color: transparent!important;
      :hover{
        color:$dark-blue;
        background-color: transparent!important;
      }
      a{
        color:$brand-primary;
        background-color: transparent!important;
        :hover{
          color:$dark-blue;
          background-color: transparent!important;
        }
      }
    }

  &-round-container{
    border-radius: 100%;
    overflow: hidden;
    border: 1px solid $not-active-gray;
  }
  &-dropdown-menu{
    &-btn{
      color: $brand-primary!important;
      &:hover, &:focus {
        color: $brand-primary!important;

        background-color: $whiteBrt !important;
        border: 0px solid #ffffff!important;
        outline:none!important;
        box-shadow:none!important;
      }
      &:active{
        color: $yellow!important;
        background-color:  $whiteBrt !important;
        border: 0px solid #ffffff!important;
        outline:none!important;
        box-shadow:none!important;
      }
    }
  }
}
.no-border{
  border: 0px solid #6f42c1!important;
}
.custom-hbr {
  color: #ebbe55;
   &:active, &:focus {
     outline: none!important;
     border: 0px solid #6f42c1!important;
   }
}
  .bg-light {
    box-shadow: 0 0 1px rgba(0, 0, 0, .25);
  }
  user{
    &-info-mail{
      //color: $white !important;
      &:any-link:-moz-focusring{
        outline: 0px dotted!important;
      }
      &:after{
      //  color:$gray-500!important;
      }
    }
  }
  .user-menu {
    color: $white !important;
    margin-right: 10px;
    padding: 0 10px 0 0;
    > .user-info{
      border-right: 1px solid #e0e0e0;
      display: inline-block;
      padding: 0.35rem 1rem;

    }
    &:any-link:-moz-focusring{
      outline: 0px dotted!important;
    }
    &:after{
      top: 1.3rem;
      position: absolute;
      vertical-align: 0.6em;
      margin-left: 12.2rem!important;//11rem!important;
      z-index: 55555555555555555;
    }
    span {
      font-size: 13px;
      line-height: 17px;
      margin-right: 10px;
      text-align: right;
      display: inline-block;
    }

  }

  .dropdown-toggle {
    &:after {
      margin-left: 1em;
      vertical-align: 0.6em;
    }
  }
  .navbar{
   &-brand{
   //  background-color: $aqua-blue!important;
     margin-right: -2rem!important;
   }
    &-nav {
      .dropdown{
        &-item{
          color: #f5f7fa;
          transition: 1s;
          &:hover{
            background-color: $whiteBrt!important;
            color: #ebbe55;
            transition: 0.8s;
          }
          &:active{
            border: 0px solid #f5f7fa!important;
          }
        }
        &-menu {
          transition: 1.8s!important;
          &-custom-color{
            background-color: $whiteBrt;
            color: #f5f7fa!important;
          }
          @include media-breakpoint-down(md) {
            position: absolute;
          }
        }
      }
    }
  }

  + .dropdown-menu {
    .dropdown-item {
      padding: 0.25rem .75rem;
      font-size: 13px;
    }
  }
  .round-container{
    overflow-y: hidden;
    display: inline-block;
    border-radius: 145px;
    max-width: 5rem;
    width: 2rem;
    height: 2rem;
  }
  img {
    width: 100%;
    margin-bottom: -50%;
  }
}