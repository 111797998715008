@import "./_variables.scss";
.msg-success{
  display:none!important;
}
.item-legend{
  background-color: transparent!important;
  border: 0px solid #f5f7fa!important;
  min-width: 15rem;
}
.rbc{
    $height:4.655rem;
  &-date{
    &-cell{
      cursor:pointer;
    }
  }
  &-today{
   background-color: $blue;
  }
  &-header{
    color: $whiteBrt!important;
    font-weight: 800;
    background-color:  $dark-blue;
    vertical-align: middle;
  }
  &-header &-today{
    background-color: $dark-blue!important;
    color: $whiteBrt;
  }
  &-off{
    &-range{
      color: $not-active-gray!important;
      font-weight: 500;
    }
  }
  &-month{
    &-view{
      background-color: $body-bg!important;
    }
  }
  &-top-border{
    border-top: 1px solid #dee2e6 !important;
  }
  &-allday-cell{
    display: none!important;
  }
  &-month-header{
    height: 2.5rem!important;
  }
  &-time-header-cell{
    height: 2.5rem!important;
  }
  &-day-slot + &-time-column &-events-container{
    margin-left: 0rem !important;
    margin-right: -0.7rem !important;
  }
  &-event  {
    padding: 2px 0px !important;
    background-color: transparent !important;
    border-radius: 5px !important;
    color: #2d2d2d !important;
    cursor: pointer;
    width: 5.8rem !important;
    display: inline-block !important;
    font: -moz-button;
    font-size: 1.2em;
    z-index: $z-index !important;
    position: absolute !important;
    bottom: 0px !important;
    margin-left: -7% !important;
    height: 95%;
    width: 14% !important;
    &-content{
      height: 100%;
    }
    &-custom{
      font-size: 0.4em;
      cursor: pointer;
      &-tasks-container{
        background-color: $dark-blue!important;
        border-radius: 0px!important;
        color: $whiteBrt !important;
        display: block !important;
        font: -moz-button;
        position: absolute!important;
        min-width: 4.6rem;
        &-checked{
          background-color: transparent!important;
         //  color: #2ff924 !important;
          margin-left: 0rem!important;
          height: 0.8rem!important;
        }
        @include media-breakpoint-down(xs) {
          line-height: 0.7!important;
          padding-bottom: 0rem!important;
          padding-top: 0rem!important;
          padding-right: 0rem!important;
          padding-left: 0rem;
          height: 2rem!important;
          margin-left: 0rem !important;
          margin-right: 0rem !important;
          font-size: 1.4em!important;
        }
        @include media-breakpoint-only(sm){
          line-height: 0.7!important;
          padding-bottom: 0rem!important;
          padding-top: 0rem!important;
          padding-right: 0.5rem!important;
          padding-left:  -0.5rem;
          height: 2rem!important;
          width: 100%;

          font-size: 2.01em !important;
        }
        @include media-breakpoint-up(md) {
          line-height: 0.7;
          padding-bottom: 0.4rem!important;
          padding-top: 0.2rem!important;

          font-size: 2.01em !important;
        }
      }

      &-profit-container{
        padding: 2px 0px!important;
        color: $dark-blue!important;
       // top: 0.25rem !important;
        margin-left: 1.2rem !important;
        font-size:2.048em !important;
        width: 1.8rem !important;
        display: inline-block !important;
        font: -moz-button;
        z-index: $z-index!important;
        position: absolute!important;
      //  top: 0.08rem!important;
        margin-left: 0.2rem!important;

        @include media-breakpoint-down(xs) {
          padding: 0px 2px!important;
          font-size: 1.4em!important;
          margin-left: 0.2rem!important;
          margin-right: 0.2rem!important;
          line-height: 1em!important;

        }
        @include media-breakpoint-only(sm){
          padding: 7px 2px!important;
          font-size: 1.7em!important;
          margin-left: 0.2rem!important;
          margin-right: 0.2rem!important;
          line-height: 0.2rem!important;
        }
        @include media-breakpoint-only(md) {
          margin-left: 1rem!important;
       //   margin-right: 0.2rem!important;
          padding: 0px 0px!important;
          line-height: 0.9rem;
        }
        @include media-breakpoint-only(lg) {
          margin-left: -0.4rem!important;
          font-size: 1.3em!important;
          padding: 0px 6px!important;
          line-height: 0.9rem;
        }
        @media screen and (min-width: 1280px) and (max-width: 1500px) {
        margin-left: -0.4rem!important;
        padding: 0px -1px!important;
        }
        @include media-breakpoint-up(xl) {
          padding: 0px 2px!important;
          line-height: 0.9rem;
        }
      }
    }
  &-date{
    &-cell{
      @include media-breakpoint-only(sm){
        font-size: 1.2em;
      }

    }
  }
  }

    &-time{

    &-header{
      margin-right: 0rem!important;
      margin-left: 0rem!important;
    }
      &-column{
         & + .rbc-day-slot > .rbc-event-custom-profit-container {
           color:$dark-blue;
           @include media-breakpoint-only(xs) {
             margin-right: -0.5rem!important;
             margin-left: -0.5!important;
           }

           @include media-breakpoint-only(sm) {
             margin-left: -1rem!important;
           }

           @include media-breakpoint-only(md) {
             margin-left: 0rem!important;
           }
           @include media-breakpoint-only(lg) {
             margin-left: 0rem!important;
           }

           @include media-breakpoint-only(xl) {
             margin-left: 0rem!important;
           }
        }
      }

    &-content{
      overflow-x: hidden!important;
    }
    &-column:nth-child(2){
      .rbc-events-container{
        right: -1px!important;
      }
    }
    &-slot{
    font-size:13px!important;
    }
  }
  &-time-gutter, &-header-gutter {
    -webkit-flex: 10;
    -ms-flex: 10;
    flex: 10;
    display: block!important;
  }

    &-time-view  &-event {
        border-radius: 0px!important;
        color: #2d2d2d;
        width: 100% !important;
        display: inline-block !important;
        height: auto;
        max-height: 100%;
        font: -moz-button;
        font-size: 1em;
    }
  &-time-view{
    background-color: $body-bg;
  }
  &-row-content  {
        z-index: $z-index - 5 !important;
        height: $height;
    }
    &-row{

        &:nth-child(1){
            z-index: $z-index - 2 !important;
            height: 5.5rem;
        }
        &:nth-child(2){
            z-index: $z-index !important;
        }
    }
  &-current{
   // border:1px solid #F98E20!important;
    background-color: $aqua-blue;
    color: #151515;
    font: -moz-button;
    height: 95%;
    width: auto!important;
    margin-left: 0.0rem;
   // transition: ;
    &-time{
      &-indicator{
        background-color: $soft-green!important;
        height: 0.5rem !important;
        width: 100%!important;
        opacity: 0.8 !important;
        left: 3.5rem!important;
        z-index: 1000000000000000000000000!important;
      }
    }

  }
 /* &-date-cell + &-current{
    &:nth-child(7){
      background-color: #007bff!important;
      padding-left: 0.2rem;
    }
  }*/
  &-now {

    margin-left: -0.15rem!important;
  }

  &-time-column + &-day-slot  &-event {
    margin-left: 0rem!important;
    background-color: $brt-blue!important;
    border: 2px solid $brand-primary!important;
    opacity: 0.9;
    &-label{
      margin-top: -0.1rem!important;
      background-color: $brand-primary!important;
      text-overflow: clip;
      white-space: normal;
      width:4.5rem;
      z-index: 10000;
      position: absolute;
      display:none!important;
    }
    &-custom-profit-container{
      font-size: 2.25em!important;
      min-width: 3.8rem!important;
      margin-left: 0rem!important;

    }
  }
  &-day-slot + &-time-column   &-events-container{
    border-left: 0px solid #ffff !important;
    border-right: 0px solid #ffff !important;
  }
}
.calendar-view {
    > .card-body {
        > .row {
            padding-bottom: 1px !important;
        }
    }
}.calendar-view {
    > .card-body {
        > .row {
            padding-bottom: 1px !important;
        }
    }
}
.label-date {
    font-weight: 300;
}
.back-next-buttons {
    button {
        background: none;
        border: none;
        color: $dark-blue;
        outline: none;
        text-transform: uppercase;
        font-size: 13px;
        padding: 0;
        cursor: pointer;

        + button {
            margin-left: 20px;
        }

        &.active {
            color: $brand-primary;
            border-bottom: 2px solid $brand-primary;
        }
      box-shadow: none!important;
    }

    .btn-next,
    .btn-back {
        font-weight: 300;
        i {
            font-size: 10px;
            color: $brand-primary;
            margin-top: -5px;
        }
    }

    .btn-next {
        i {
            transform: rotate(270deg);
        }
    }

    .btn-back {
        i {
            transform: rotate(90deg);
        }
    }
}
.cursor{
  &-pointer{
    cursor: pointer!important;
  }
}
.sk-cube-grid {
  width: 5px;
  height: 5px;
  margin-top: 5px;
  margin-bottom: -10px;
  margin-left: 3px;
  margin-right: 0px;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: $whiteBrt;
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}
.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s; }
.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s; }
.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s; }
.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s; }
.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s; }
.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s; }
.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s; }
.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s; }
.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s; }

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  } 35% {
      -webkit-transform: scale3D(0, 0, 1);
      transform: scale3D(0, 0, 1);
    }
}

@keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  } 35% {
      -webkit-transform: scale3D(0, 0, 1);
      transform: scale3D(0, 0, 1);
    }
}