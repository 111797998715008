@import "./_variables.scss";
.custom-control-inline {
  line-height: 24px;
}

.custom-control-input {
  margin-left: -10px;
  z-index: 0;
}

.switch {
  width: 24rem;
  position: relative;

  label {
    color: #63676f;
    transition: opacity 0.25s ease;
    cursor: pointer;
    text-transform: uppercase;
  }

  .toggle-outside {
    height: 100%;
    padding: 0.25rem;
    overflow: hidden;
    transition: 0.25s ease all;
  }

  .toggle-inside {
    background: $white;
    position: absolute;
    transition: 0.25s ease all;
  }

  input {
    position: absolute;
    top: 0;
    z-index: 2;
    opacity: 0;
    cursor: pointer;

    &:checked {
      z-index: 1;

      + label {
        color: $brand-primary;
        cursor: default;
      }
    }
  }

  &--horizontal {
    width: 10rem;
    height: 1.5rem;
    margin: 0 auto 1rem;
    font-size: 0;

    input {
      height: 1.5rem;
      width: 4rem;
      left: 3rem;
      margin: 0;

      &:checked {
        ~ .toggle-outside {
          .toggle-inside {
            left: 0.25rem;
          }
        }
      }

      ~ input {
        &:checked {
          ~ .toggle-outside {
            .toggle-inside {
              left: 1.94rem;
            }
          }
        }
      }
    }

    label {
      font-size: .8rem;
      line-height: 1.6rem;
      display: inline-block;
      width: 3rem;
      height: 100%;
      margin: 0;
      text-align: center;

      &:last-of-type {
        margin-left: 4rem;
      }
    }

    .toggle-outside {
      background: $brand-primary;
      position: absolute;
      width: 4rem;
      left: 3rem;
    }

    .toggle-inside {
      height: 1rem;
      width: 1.8rem;
    }
  }

  &.wide {
    width: 13rem;

    &.switch--horizontal {
      input {
        width: 4rem;
        left: 4.7rem;
      }

      .toggle-outside {
        left: 4.7rem;
      }

      label {
        left: 4rem;
        width: 4rem;

        &:last-of-type {
          margin-left: 5rem;
        }
      }
    }
  }
}

.custom-dropdown {
  .dropdown-toggle {
    border-bottom: 2px solid $brand-primary;
    padding: 0;

    &:focus {
      text-decoration: none;
    }

    .camera-details & {
      border-bottom: 2px solid $brand-primary;
      padding: 0;
    }

    &:hover {
      text-decoration: none;
    }
  }
}

form {
  input.form-control {
    background: transparent;
    border: none;
    border-bottom: 2px solid $brand-primary;
    padding: 0.375rem 0;
    color: $white;

    &[readonly]{
      background-color: transparent;
    }

    &:focus {
      background: transparent;
      box-shadow: none;
      //color: $gray-700;
    }

    + a {
      margin-top: -32px;
      position: absolute;
      right: 22px;
    }
  }

  /* Change Autocomplete styles in Chrome*/
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: $whiteBrt;
    background-color: transparent !important;
    //-webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
  }
}