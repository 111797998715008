.user-list {
  img {
    border-radius: 50%;
  }
  a {
    &.edit-user {
      color: $white;

      &:hover {
        text-decoration: none;
      }
    }
  }

  table {
    tr {
      td {
        vertical-align: middle;
      }
    }
  }
}