.Dashboard {
  .MapWrapper {
    position: relative;
    padding: 0;
    height: 100%;
    min-height: 320px;

    iframe {
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }

  .Handle {
    position: relative;

    &__line {
      position: absolute;
      top: calc(50% - 1px);
      left: 0;
      width: 100%;
      height: 0;
      border-bottom: 2px dashed rgba(0,0,0,0.05);
    }

    &:hover, &:active {
      .Handle__line {
        border-bottom: 2px dashed rgba(0,0,0,0.15);
      }
    }
  }
}
