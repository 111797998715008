@import "./_variables.scss";

.signin-bg {
  background: rgba(235, 190, 85, 0.16);
  background: -moz-linear-gradient(
    top,
    rgba(235, 190, 85, 0.16) 0%,
    rgba(235, 190, 85, 0.34) 22%,
    rgba(235, 190, 85, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(235, 190, 85, 0.16)),
    color-stop(22%, rgba(235, 190, 85, 0.34)),
    color-stop(100%, rgba(235, 190, 85, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(235, 190, 85, 0.16) 0%,
    rgba(235, 190, 85, 0.34) 22%,
    rgba(235, 190, 85, 1) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(235, 190, 85, 0.16) 0%,
    rgba(235, 190, 85, 0.34) 22%,
    rgba(235, 190, 85, 1) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(235, 190, 85, 0.16) 0%,
    rgba(235, 190, 85, 0.34) 22%,
    rgba(235, 190, 85, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(235, 190, 85, 0.16) 0%,
    rgba(235, 190, 85, 0.34) 22%,
    rgba(235, 190, 85, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ebbe55', endColorstr='#ebbe55', GradientType=0 );
}

.signin {
  &-container {
    background: url("../../images/signin-bg.png") center center no-repeat;

    @include media-breakpoint-down(sm) {
      background-size: cover;
      background-position-x: inherit;
      background-position-y: top;
    }
    @include media-breakpoint-only(md) {
      background-size: cover;
      background-position-x: left;
    }

    @media screen and (min-width: 768px) and (max-width: 968px) {
      background-position-x: right;
    }
    @include media-breakpoint-only(lg) {
      background-size: cover;
      background-position-x: right;
      background-position-y: top;
    }
    @include media-breakpoint-up(xl) {
      background-size: cover;
      background-position-x: right;
      background-position-y: bottom;
    }
  }
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.6);
  background: rgba($gray-800, 0.7);
  color: $white;
  margin: auto;

  $circelF: 30px;

  .checkbox {
    font-weight: 400;
  }
  .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;
    &:focus {
      z-index: 2;
    }
  }
  input {
    &[type="email"] {
      margin-bottom: -1px;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    &[type="password"] {
      margin-bottom: 10px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    &:not(output):-moz-ui-invalid {
      box-shadow: none;
      border-bottom: 2px solid $red;
    }
  }
  .spinner {
    margin: 20px auto 0;
    width: 70px;
    text-align: center;
  }
  .spinner > div {
    width: 18px;
    height: 18px;
    background-color: $brand-primary;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  @-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
}
