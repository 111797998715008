@import "./_variables.scss";
.recharts{
  &-default-legend{
    @include media-breakpoint-down(xs) {
      width:100%!important;
    }

  }
  &-legend-wrapper{
    width:100%!important;
   // bottom: 40px;
   /* @include media-breakpoint-only(xs) {
      margin-left: -22%!important;

    }
    @include media-breakpoint-only(sm) {
      margin-left: 35%;
      margin-right: 35%;
      bottom: -40px!important;

    }
    @include media-breakpoint-only(md) {
      margin-left: 35%;
      margin-right: 35%;
      bottom: -20px!important;
    }
    @include media-breakpoint-only(lg) {
      margin-left: 35%;
      margin-right: 35%;
      bottomom:-11px!important;
    }
    @include media-breakpoint-only(xl) {
      margin-left: 45%;
      margin-right: 50%;
      padding: 0px 2px!important;
      bottomom:-11px!important;
    }*/

  }
}
.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}
.mt-1{
  margin-top: 1rem!important;
}
.px-1{
  padding-left: 1.9rem!important;
  padding-right: 1.9rem!important;
}
.py-1{
  padding-top: 1.9rem!important;
  padding-bottom: 1.9rem!important;
}
.py-half{
  padding-top: 1.2rem!important;
  padding-bottom: 1.2rem!important;
}

/*
 * Content
 */

[role="main"] {
  padding-top: 64px; /* Space for fixed navbar */
  min-width: calc(100% - 200px);
  max-width: calc(100% - 200px);
  transition: max-width 0.4s ease , min-width 0.4s ease;

  .terms & {
    min-width: 100%;
    max-width: 100%;
  }

  @media (max-width: 1024px) {
    min-width: calc(100% - 64px);
    max-width: calc(100% - 64px);
  }
  
  > .globus-alert-primary {
    background-color: #0a4453;
    border: none;
    color: $white;
    position: fixed;
    top: 48px;
    transition: ease all .4s;
    width: calc(100% - 200px);
    z-index: 2;

    &.hide {
      top: -50px;
    }
  }
}


/*
 * Navbar
 */

.navbar {
  .form-control {
    padding: .75rem 1rem;
    border-width: 0;
    border-radius: 0;
  }
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, .1);
  border-color: rgba(255, 255, 255, .1);

  &:focus {
    border-color: transparent;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
  }
}

/*
 * Utilities
 */

.border-top { border-top: 1px solid #e5e5e5; }
.border-bottom { border-bottom: 1px solid #e5e5e5; }

.loader {
  &-map{
    width: 100px!important;
    height: 100px!important;
  }
  border: 5px solid #f3f3f3!important;
  border-top: 5px solid $aqua-blue!important;
  border-radius: 50%!important;
  width: 90px!important;
  height: 90px!important;
  animation: spin 2s linear infinite!important;
  background-color: transparent;
}
.loader-big {
  border: 5px solid #f3f3f3!important;
  border-top: 5px solid $aqua-blue!important;
  border-radius: 50%!important;
  width: 140px!important;
  height: 140px!important;
  animation: spin 2s linear infinite!important;
  background-color: transparent;
}
.dashboard{
  &-calendar{
    &-switcher{
    margin-bottom: 0rem!important;
    }
  }
}
@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}