.ListItem {

  &__column {
    &_sortDirection {
      flex: 0 0 40px;
    }
    &_toggle {
      flex: 0 0 calc(120px - 0.36rem);
    }
  }
}

.tooltip.tt-basic {
  .tooltip-inner {
    background-color: #004555!important;
    color: #ffffff!important;
    font-size: 12px!important;
    font-weight: 400;
    max-width: 200px;
    padding: 3px 10px;
    .row {
      padding: 0!important;
    }
  }

  .arrow::before {
    border-top-color: #004555!important;
  }
}
