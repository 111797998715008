.faqs {
  .list-group-flush {
    .list-group-item {
      padding: 0 0 20px 0;
      border-bottom: 0;

      .card {
        margin-bottom: 0 !important;
      }

      a.btn {
        font-weight: 300;

        i {
          color: $brand-primary;
          font-size:8px;
          line-height: 24px;
        }
      }
    }
  }
}